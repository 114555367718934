var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items.items,"item-key":"id","items-per-page":25,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.items.meta.totalItems,"footer-props":{
      'items-per-page-options': _vm.rowsPerPageItemsGlobal
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-inline-flex justify-space-between align-center pr-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-inline-flex align-center"},[_c('v-switch',{staticClass:"pa-3",attrs:{"label":_vm.$lang.labels.activeOnly},model:{value:(_vm.activeOnly),callback:function ($$v) {_vm.activeOnly=$$v},expression:"activeOnly"}}),_c('v-text-field',{staticClass:"pt-2",attrs:{"outlined":"","clearable":"","dense":"","label":_vm.$lang.labels.searchByName,"data-cy":"search-by-name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"pt-2 pl-2",attrs:{"items":_vm.typesFormatted,"label":_vm.$lang.labels.type,"outlined":"","dense":"","clearable":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('div',{staticClass:"pb-2"},[_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('fetchCredentials', { options: _vm.options, search: _vm.search && _vm.search.length > 1 ? _vm.search : '', type: _vm.selectedType, resetPage: true })}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),(_vm.roles.includes('CREDENTIAL_CREATOR'))?_c('v-btn',{staticClass:"color-accent-text",attrs:{"color":"accent"},on:{"click":function($event){_vm.showTypeSelector = true}}},[_vm._v(_vm._s(_vm.$lang.actions.create)+" ")]):_vm._e()],1)]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("# "+_vm._s(item.id))])]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$lang.status[item.type]))])]}},{key:"item.createdOn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.createdOn)))])]}},{key:"item.modifiedOn",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.modifiedOn)))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.$lang.status[item.status]))])]}},{key:"item.isSystem",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.isSystem ? _vm.$lang.status.YES : _vm.$lang.status.NO))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-btn',{staticClass:"mx-1 button-default-width",attrs:{"color":"secondary","data-cy":"credential-activation"},on:{"click":function($event){return _vm.$emit('changeStatus', {id: item.id, status: item.status})}}},[_vm._v(" "+_vm._s(item.status === 'ACTIVE' ? _vm.$lang.actions.deactivate : _vm.$lang.actions.activate)+" ")]),_c('v-btn',{staticClass:"mx-0 button-default-width",attrs:{"color":"primary","to":{ name: 'credentialEdit', params: { id: item.id } }}},[_vm._v(_vm._s(_vm.$lang.actions.edit)+" ")]),(_vm.roles.includes('CREDENTIAL_CREATOR'))?_c('v-btn',{staticClass:"ml-1 button-default-width color-accent-text",attrs:{"color":"accent"},on:{"click":function($event){_vm.copyCreateResource(item, 'credentialCreate', _vm.$router, item.type.toLowerCase(), _vm.$options.filters.formatDateTime(new Date()))}}},[_vm._v(_vm._s(_vm.$lang.actions.copyCreate))]):_vm._e()],1)]}}])}),(_vm.showTypeSelector)?_c('v-dialog',{attrs:{"max-width":"30%"},model:{value:(_vm.showTypeSelector),callback:function ($$v) {_vm.showTypeSelector=$$v},expression:"showTypeSelector"}},[_c('create-dialog',{attrs:{"types":_vm.credentialTypes},on:{"create":_vm.createCredential,"closeDialog":function($event){_vm.showTypeSelector = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }